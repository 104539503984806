import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { list } from '../../../assets/data/pageStructure'
import styled from 'styled-components'
import { down } from 'styled-breakpoints';
import { ContainerMob } from '../../../utils/utils'
import { Text } from '../../../utils/typo'

const Foot = styled.footer`
    text-align: center;
    background-color: ${props => props.bgColor || props.theme.color.white};
`;

const FooterWrapper = styled(ContainerMob)`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 2rem;
    padding-top: 2rem;
    padding-bottom: 2rem;

    ${down('sm')} {
        grid-template-columns: repeat(2, 1fr);
    }

    ${down('xs')} {
        grid-template-columns: repeat(1, 1fr);
    }

    p, li, a {
        white-space: pre-wrap;
    }
`;

const FooterAdres = styled.div`
    text-align: left;
    ${down('xs')} {
        display: none;
    }
`;

const FooterKontakt = styled.div`
    ${down('sm')} {
        text-align: right;
    }

    ${down('xs')} {
        text-align: center;
    }

    a {
        font-weight: 700;
        transition: all .4s ease-in-out;
        margin-bottom: .6rem;
        display: block;

        :hover {
            font-weight: 700;
            color: ${({ theme }) => theme.color.grey8};
        }
    }
`;

const FooterLinki = styled.ul`
    list-style-type: none;
    line-height: 1;
    text-align: right;
    ${down('sm')} {
        display: none;
    }

    a {
        font-weight: 400;
        line-height: 1;
        transition: all .4s ease-in-out;

        :hover {
            font-weight: 500;
            color: ${({ theme }) => theme.color.grey8};
        }
    }

`;

const Footer = ({
    tel,
    email,
    ulica,
    nrBud,
    miasto,
    kod,
    woj,
    kraj,
    bgColor }) => {

    const menu = list.map(item => (
        <li key={item.name}>
            <Link activeClassName="active"
                to={item.path}>{item.name}</Link>
        </li>
    ));

    return (
        <Foot bgColor={bgColor}>
            <FooterWrapper>
                <FooterAdres>
                    <Text
                        weight='600'
                        mb='.6rem'>
                        Adres —
                    </Text>
                    <p>ul. {ulica} {nrBud}</p>
                    <p>{kod} {miasto}</p>
                    <p>Województwo {woj}</p>
                    <p>{kraj}</p>
                </FooterAdres>

                <FooterKontakt>
                    <Link to='/kontakt'>Kontakt —</Link>
                    <p>+48 {tel}</p>
                    <p>{email}</p>
                </FooterKontakt>

                <FooterLinki>
                    <Text
                        weight='600'
                        mb='.6rem'>
                        Menu —
                    </Text>
                    {menu}
                </FooterLinki>
            </FooterWrapper>
        </Foot>
    )
}
export default Footer