import React from 'react'
import Layout from '../layout/layoutBW'
import { Container } from '../utils/utils'

const Page404 = () => {

  return <div>
    <Layout>

      <Container>
        <h1>404</h1>
        <h6><span>Niestety szukana strona nie istnieje</span></h6>
      </Container>

    </Layout>
  </div>
}
export default Page404