import React from 'react'
import { ThemeProvider } from 'styled-components'
//STYLES
import { GlobalStyle } from '../utils/globalStye'
import { theme } from '../utils/theme'
//DATA
import { info } from '../assets/data/kontakt'
//COOKIES
import Cookie from '../components/cookie/Cookie'
//NAVBAR
import Navbar from '../components/navbar/Navbar_bw'
//import NavbarHeader from '../components/navbar/NavbarHeader_Simple'
//FOOTER
import Footer from '../components/footer/footer/Footer_bw'
import FooterHeader from '../components/footer/footerHeader/FooterHeader_bw'
import FooterIks from '../components/footer/footerIks/FooterIks'

const Layout = (props) => {

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <div>
        <Navbar />
        <div>{props.children}</div>
        <Cookie />
        <FooterHeader
          heading={footer.heading}
          subHeading={footer.subHeading} />
        <Footer
          tel={info.tel}
          email={info.mail}
          ulica={info.ulica}
          nrBud={info.nrBud}
          miasto={info.miasto}
          kod={info.kod}
          woj={info.woj}
          kraj={info.kraj}
        />
        <FooterIks />
      </div>
    </ThemeProvider>

  )
}
export default Layout

export const footer = {
  heading: 'Zadzwoń do nas już dziś!',
  subHeading: 'plakaty? ulotki? wizytówki? na dziś? a może designera?',
}