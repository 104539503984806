import React from 'react'
import styled from 'styled-components'
import { Container } from '../../../utils/utils'
import { LabelBW, H1 } from '../../../utils/typo'

const FooterHeaderWrapper = styled.div`
    background: ${props => props.bgColor || props.theme.color.white};
    padding-top: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    hr {
        margin-bottom: 0;
        margin-top: 4rem;
        opacity: ${props => props.hrOpacity || 1};
        border: 0;
        height: 0;
        border-top: 1px solid rgba(0, 0, 0, 0.5);
        border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    }
`;

const FooterHeader = ({
    heading,
    subHeading,
    bgColor,
    hrOpacity }) => {
    return (
        <FooterHeaderWrapper
            bgColor={bgColor}
            hrOpacity={hrOpacity}>
            <Container>
                <LabelBW mb='1rem'>{subHeading}</LabelBW>
                <H1 as='p'>{heading}</H1>
                <hr />
            </Container>
        </FooterHeaderWrapper>
    )
}
export default FooterHeader