import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import styled from 'styled-components'
import { ContainerMob } from './../../../utils/utils'

const Stopka = styled.div`
    padding-bottom: 1rem;
    text-align: center;
    background-color: ${props => props.bgColor || props.theme.color.prim1};

    a, p {
        color: ${({ theme }) => theme.color.white};
        opacity: .3;
        font-weight: 300;
        line-height: 1;
    }

    p {
        margin-bottom: 1rem;
        margin-top: 1rem;
    }

    a {
        font-weight: 400;
        opacity: .5;
        margin-left: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all .4s ease-in-out;
        :hover {
            font-weight: 500;
            opacity: .7;
        }

        >div {
            margin-right: 1rem;
        }
    }

    hr {
        margin-bottom: 1rem;
        margin-top: 0;
        opacity: ${props => props.hrOpacity || 0.8};
        border: 0;
        height: 0;
        border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    }
`;

const FooterIks = (props) => {

    return (
        <Stopka
            bgColor={props.bgColor}
            hrOpacity={props.hrOpacity}>
            <ContainerMob>
                <hr />
            </ContainerMob>
            <Link to='/polityka-prywatnosci'>
                Polityka prywatności
            </Link>
            <p>{new Date().getFullYear()} © Wszystkie prawa zastrzeżone<br />
                Projekt i realizacja:</p>

            <a
                href="https://iksdesign.pl/"
                target='blank'>
                <StaticImage
                    src='../../../assets/images/logo/logo_white.png'
                    alt='Logo'
                    placeholder='blurred'
                    layout='fixed'
                    height={20}
                    transformOptions={{ fit: 'contain' }}
                />
                IKS Design
            </a>


        </Stopka>
    )
}
export default FooterIks